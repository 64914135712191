<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import Multiselect from "vue-multiselect";
// import {
//   required,
//   email,
//   minLength,
//   sameAs,
//   maxLength,
//   minValue,
//   maxValue,
//   numeric,
//   url,
//   alphaNum,
// } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Pass",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Liste des Pass",
      tmpCoverFile: null,
      file: null,
      eventId: 0,
      fileModif: null,
      items: [
        {
          text: "Évènement",
          href: "/",
        },
        {
          text: "Liste des évènements",
          active: true,
        },
      ],
      type: 'text',
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],

      codeParrainageFormModif: {
        id: 0,
        code: "",
        user: "",
        etat: "",
        nombrefilleul: 0,
      },
      parrainRow: null,
      totalRows: 1,
      currentPage: 1,
      currentPageActif: 1,
      currentPagePasse: 1,
      perPage: 100,
      perPagePasse: 100,
      perPageActif: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterActif: null,
      filterPast: null,
      codeevent: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "passName", sortable: true, label: "Intitulé" },
        { key: "type", sortable: true, label: "Type" },
        { key: "amount", sortable: false, label: "Prix" },
        { key: "commission", sortable: false, label: "Commission" },
        { key: "days", sortable: true, label: "Validité" },
        { key: "stock", sortable: false, label: "Quantité Total" },
        { key: "remaining", sortable: false, label: "Quantité Restante" },
        { key: "description", sortable: false, label: "Description" },
        { key: "dateLimite", sortable: false, label: "Date" },
        { key: "action", sortable: true, label: "Action" },
      ],
      passForm: {
        dateLimite: "",
        heureLimite: "",
        amount: 0,
        stock: 0,
        remaining: 0,
        days: 0,
        description: "",

      },
      buyModal: false,
     
      pass:{
        nombreVoulu: 1,
        passId: 1,
        idUser: 0
      },
      userFound: [],

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showActive: false,
      erreur: false,
      showRecharge: false,
      headerBgVariant: "warning",
      headerTextVariant: "info",
      bodyBgVariant: "light",
      showpopGainParrain: false,
      showpopGainMembre: false,
      gain_parrain_membre: {
        parrain: 0,
        membre: 0,
      },
      new_parrain: null,
      new_gain: null,
      startValue: 1,
      endValue: 500,
      parbon: 500,
      totalValueActif: 0,
      totalValuePasse: 0,
      userChargeLoader: false,
      newDataEvenement: [],
      recherche: "",
      eventInactifListe: [],
      eventActifListe: [],
      eventPasseListe: [],
      eventActifListeLength: 0,
      currentPassId: 0,
      showEditEvent: false,
      loadAttente: false,
      loadActif: false,
      loadPasse: false,
    };
  },
  validations: {},

  mounted() {
    this.passActifList();
    // this.evenementInactifList();
    // this.evenementPasseList();
    this.init();
    this.passId = this.$route.params.passId;


    setTimeout(() => {

      this.loadAttente = false
      this.loadActif = false
      this.loadPasse = false




    }, 3000);

  },

  methods: {


    async init() {

      this.eventId = this.$route.params.eventId;
      this.passActifList()

    },







    async passActifList() {
      this.eventId = this.$route.params.eventId;


      //var event =44;
      const data = await apiRequest(
        "GET",
        `evenement/all-pass/?eventId=${this.eventId}`,
        undefined,
        false
      );

      //console.log("pass Id",this.eventId);



      if (data && data.data) {

        console.log("all pass", data)


        const eventFormatTableActif = data.data.map((onePass) => {


          return {
            id: onePass.id,
            passName: onePass.pass,
            dateLimite: new Date(onePass.dateLimite).toLocaleString("FR-fr"),
            stock: onePass.stock,
            remaining: onePass.quantiteDispo,
            commission: onePass.commission,
            type: onePass.type,
            days: onePass.nombreJours,
            amount: onePass.montant,
            description: onePass.description || "pas de description",

          }
        });

        this.eventActifListe = eventFormatTableActif;


        this.totalValueActif = this.eventActifListe.length;
      }
    },



    emptyEditRow() {
      this.passForm = {
        amount: "",
        days: "",
        passName: "",


      }
    },

    editRow(row) {

      this.showEditEvent = true;
      this.currentPassId = row.id;
      this.passForm.passName = row.passName;
      this.passForm.amount = row.amount;
      this.passForm.days = row.days;
      this.passForm.dateLimite = row.dateLimite.split(' ')[0];
      this.passForm.heureLimite = row.dateLimite.split(' ')[1]//.slice(0, 5);
      this.passForm.stock = row.stock;

    },


    onFilteredInactif(filteredItems) {
      this.totalValueInactif = filteredItems.length;
      this.currentPage = 1;
    },
    deactivateRow(row) {
      this.showdisable = true;
      this.currentPassId = row.id;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.currentPassId = row.id;
    },
    activeRow(row) {
      this.showActive = true;
      this.currentPassId = row.id;
    },
    closeModal() {
      this.showDelete = false;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },
    async onUpdateEvent() {

      try {




        const numPassId = +this.currentPassId
        const updatePass = await apiRequest(
          "POST",
          "evenement/tiket-pass-update",
          {
            passId: numPassId,
            pass: this.passForm.passName,
            montant: this.passForm.amount,
            nombreJours: this.passForm.days,
            dateLimite: `${this.passForm.dateLimite}T${this.passForm.heureLimite}:00.000`
          },
          false
        );



        if (updatePass && updatePass.data) {


          this.passActifList();


          this.showEditEvent = false;

          this.$toast.success("Information  modifiée avec succès", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.emptyEditRow()
        }

      } catch (error) {

        this.$toast.error("Une erreur est survenue", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

      }





    },

    async activeevent() {
      this.showActive = false;
      const update = await apiRequest(
        "POST",
        "evenement/action",
        { id: this.currentPassId, visible: true },
        false
      );

      if (update) {

        this.$toast.success("Évènement validé avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.evenementInactifList();
        this.passActifList()

      }
    },

    async deletePass() {
      this.showDelete = false;
      const update = await apiRequest(
        "POST",
        "evenement/delete",
        { id: this.currentPassId, visible: false },
        false
      );

      if (update) {


        this.$toast.success("Information supprimée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.evenementInactifList()
        this.passActifList();
        this.evenementPasseList();
        this.showDelete = false;

      }
    },

    async deletePassi() {
      this.showdisable = false;
      const update = await apiRequest(
        "POST",
        "evenement/action",
        { id: this.currentPassId, visible: false },
        false
      );

      if (update) {


        this.$toast.success("Information désactivée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.evenementInactifList()
        this.passActifList();

      }
    },
    async userSearch() {
      //this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {
        this.paginatedDate = [];
        console.log(dataSearch.data);
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname != null ? user.firstname : "",
            lastname: user.lastname != null ? user.lastname : "",
            pays: user.pays != null ? user.pays : "",
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            email: user.email != null ? user.email : "",
            //trajet: user.nombreTrajet != null ? user.nombreTrajet : "",
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated != null ? user.userValidated : "",
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.userFound = formattedTable;
        console.log(this.userFound);
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

        this.userChargeLoader = false;
      }
    },

    handlePassModal(pass) {
      console.log("pass", pass)
      this.pass.passId = pass.id;
      this.buyModal = true

    },

    selectTarget(user) {
      console.log(user)


      this.pass.idUser = user.id;



    },

    async sellPass() {
      try {

        const response = await apiRequest('POST', 'evenement/ticket-selle-from-dash', {
        nombreVoulu: this.pass.nombreVoulu,
        passId: this.pass.passId,
        idUser: this.pass.idUser

      })

      if (response.status == 200) {
        console.log(response.data)
        window.open(response.data.lien,'_blank','noreferrer')
        this.buyModal = false
        this.$toast.success('Pass vendu avec succès !')
      } else {

        this.$toast.error('Un problème survenu !')

      }
        
      } catch (error) {
        this.$toast.error('Un problème survenu !')
        
      }
      

    },



    // setEventAdress: function (place) {

    //   if (place) {       
    //     this.passForm.adresseEvent = place.formatted_address;
    //     this.passForm.longitude = place.geometry.location.lng();
    //     this.passForm.latitude = place.geometry.location.lat();
    //   }
    // },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">

        <div class="card">
          <div class="containerDot" v-if="loadAttente == true || loadActif == true || loadPasse == true">
            <span class="loading">Chargement en cours... </span>

          </div>

          <div class="card-body">
            <div class="row d-flex justify-content-between p-2">
              <div class="card-title">
                <!--     <h5>Gérer</h5> -->
              </div>

            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Pass Actifs</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm"
                            :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterActif" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="eventActifListe
      " :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">

                      <template v-slot:cell(action)="row">
                        <a v-if="row.item.visible == true" href="javascript:void(0);" class="text-danger"
                          @click="deactivateRow(row.item)" v-b-tooltip.hover title="désactiver">
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>

                        <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover @click="editRow(row.item)"
                          title="Editer">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>


                        <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="deleteRow(row.item)"
                          title="Editer">
                          <i class="mdi mdi-delete font-size-18"></i>
                        </a>

                        <a href="javascript:void(0);" class="text-info" v-b-tooltip.hover
                            @click="handlePassModal(row.item)" title="Vendre">
                            <i class="mdi mdi-basket font-size-18"></i>
                          </a>

                      </template>

                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPageActif" :total-rows="totalValueActif"
                            :per-page="perPageActif" :aria-controls="trajet"></b-pagination>
                        </ul>
                      </div>
                      <!--  <transition mode="out-in">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
           
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button
                                class="myBouton"
                                @click="firstVal()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Prem"
                              >
                                <ion-icon
                                  name="play-skip-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                type="button"
                                class="myBouton"
                                @click="decrement()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Préc"
                              >
                                <ion-icon
                                  name="chevron-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="increment()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Suiv"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="chevron-forward-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="incrementToLast()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Dern"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="play-skip-forward-outline"
                                ></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition> -->
                    </div>
                  </div>
                </b-tab>


              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEditEvent"
      title="Editer le Pass"
      title-class="font-18"
      hide-footer
    >

      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="ModifSubmit"
      >
        <div class="row">
         

          <div class="col-md-6 mb-3">
            <label for="passName">Intitulé du Pass</label>
            <input
              type="text"
              class="form-control py-4"
              id="passName"
              v-model="passForm.passName"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="amount">Prix du Pass</label>
            <input
              type="text"
              class="form-control py-4"
              id="amount"
              v-model="passForm.amount"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="passForm.days">Validité du Pass</label>
            <input
              type="text"
              class="form-control py-4"
              id="passForm.days"
              v-model="passForm.days"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="dateLimite">Date Limite</label>
            <input
              type="date"
              class="form-control py-4"
              id="dateLimite"
              v-model="passForm.dateLimite"
            />
          </div>
        </div>
        <button class="btn btn-Back float-right" type="submit"  @click="onUpdateEvent()">
          Modifier
        </button>
      </form>
    </b-modal> -->

    <b-modal id="modal-backdrop" centered v-model="erreur" title="Veuillez remplir toutes les options."
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false">Ok</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce code de parrainage ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePass">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment supprimer cet évènement?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePass">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showActive" title="Voulez-vous vraiment activé cet évènement ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activeevent()">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-center" centered size="xl" v-model="showEditEvent" title="Editer le Pass" title-class="font-18"
      hide-footer>

      <form class="form-horizontal" role="form" method="post" @submit.prevent="ModifSubmit">
        <div class="row">


          <div class="col-md-6 mb-3">
            <label for="passName">Intitulé du Pass</label>
            <input :type="text" class="form-control py-4" id="passName" v-model="passForm.passName" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="amount">Prix du Pass</label>
            <input :type="type" class="form-control py-4" id="amount" v-model="passForm.amount" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="days">Validité du Pass</label>
            <input :type="type" class="form-control py-4" id="days" v-model="passForm.days" />
          </div>
          <div class="col-md-6 mb-3">

            <label for="dateDebut">Heure de la date Limite</label>
            <input type="type" class="form-control py-4" id="heureLimite" onfocus="(this.type='time')"
              v-model="passForm.heureLimite" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="dateFin">Date Limite</label>
            <input class="form-control py-4" id="dateLimite" v-model="passForm.dateLimite" :type="type"
              onfocus="(this.type='date')" />
          </div>

        </div>
        <button class="btn btn-Back float-right" type="submit" @click="onUpdateEvent()">
          Modifier
        </button>
      </form>
    </b-modal>

    <b-modal size="lg" id="my-modal" v-model="buyModal" title="Vendre un pass" hide-footer>
      <b-card>

        <b-card-sub-title> Vendre un pass event</b-card-sub-title>


        <b-container fluid>

          <div class="row mt-3">

            <div class="col-md-6">


              <label for="search" class="form-label">Chercher un utilisateur</label>
              <input id="search" type="text" v-model="recherche" @input="userSearch" class="form-control"
                placeholder="Rechercher..." style="width:80%" />
              <!--  v-if="suggestions.length"  -->
              <ul class="suggestions mt-2 p-2 " style="width:80%; background-color:#eee; border-radius:4px;">

                <li v-for="(user, index) in userFound" :key="index">

                  <div class=" d-block d-flex justify-content-between">
                    <div>{{ user.firstname + " " + user.lastname }}</div>
                    <div><input type="checkbox" class=" d-block " :value="user.id" @click="selectTarget(user)"></div>

                  </div>


                  <hr class="my-1">
                </li>
                <li class="d-flex justify-content-center">
                  ...
                  <hr class="my-1">
                </li>
              </ul>

            </div>

            <div class="col-md-6">
              <transition name="slide">
                <div class="step-1">
                  <!-- Contenu de la première étape -->





                  <div class="form-group mb-2">

                    <label for="date" class="form-label">Nombre de pass</label>
                    <input type="text" v-model="pass.nombreVoulu" class="form-control w-50">
                  </div>

                  <!-- <div class="form-group mt-2 mb-2">
                    <label for="hr" class="form-label">Horraire de départ</label>
                    <select v-model="buy.horraire" id="hr" class="form-control">
                      <option v-for="item in selectedTime" :key="item" :value="item"> {{ item.replace('"', '') }}
                      </option>
                    </select>
                  </div> -->


                  <!-- <b-table class="searchField" striped responsive hover :items="selectedUser" :fields="userField">
                    


                  </b-table> -->

                </div>


              </transition>
            </div>



          </div>
          <div class='row d-flex justify-content-center'>
            <a href="#" class="btn btn-primary" @click.prevent="sellPass">Vendre </a>
          </div>



        </b-container>


      </b-card>

      <!-- <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in showLine" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
        </tr>
      </tbody>
    </table> -->
    </b-modal>
  </Layout>
</template>
<style>
tr td {
  text-align: center;
  text-wrap: nowrap;
}




.alignBtn {
  display: flex;
  float: right;

  align-items: flex-end;
}

.bg-secondary-special {
  background: #3aaa35;
}

.bg-secondary-smile {
  background: #3aaa35;
}

.widget-wrapping {
  display: flex !important;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.thesize {
  font-size: 2em;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.typeParrain {
  margin-top: 0.444rem;
  font-size: 17px;
  color: #fff;
}

.gain {
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.fa-edit {
  font-size: 17px;
  cursor: pointer;
}

thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;

}


.pac-container {
  z-index: 10000 !important;
}

.btn-Back {
  background: #3aaa35;
  color: #fff;
}


.modal-header {
  background: #3aaa35;

}


.modal-title {
  color: #fff;
}


.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}
</style>
